* {
  margin: 0;
  padding: 0;
}

body,
html,
#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

html {
  background: #000000; /* Old browsers */
  background: -moz-radial-gradient(#000000 0%, #1e0144 100%); /* FF3.6-15 */
  background: -webkit-radial-gradient(
    #000000 0%,
    #1e0144 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(
    #000000 0%,
    #1e0144 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#1e0144',GradientType=1 ); /* IE6-9 */
}

.canvas {
  width: 100%;
}

h1 {
  background: -webkit-linear-gradient(rgb(255, 255, 255), rgb(255, 200, 45));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  pointer-events: none;
  text-align: center;
}

h2 {
  background: -webkit-linear-gradient(rgb(255, 45, 200), rgb(255, 255, 255));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  pointer-events: none;
}

.disableInteraction {
  pointer-events: none;
}

.container {
  position: relative; /* Set a positioning context for the containing div */
  width: 100%; /* Set the width of the container as needed */
  height: 100vh; /* Set the height of the container as needed */
}

.centered-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dots {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 25%);
}
